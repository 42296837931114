import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import ShareLinks from './ShareLinks'

const NewsCard = ({post}) => (
  <div className="news-preview-item">

    <Link to={post.fields.slug}>
    <Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} alt="" style={{ height: '154px', borderTopLeftRadius: '2px', borderTopRightRadius: '2px' }} />
    </Link>
    <div className="news-preview-content">
      <p className="is-size-5">
      <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
      <br /><small className="accent-color">{post.frontmatter.date}</small></p>
    </div>
    <div className="news-preview-footer">
      <Link className="button is-white" to={post.fields.slug}>Read More</Link>
      <div className="dropdown is-hoverable is-up">
        <div className="dropdown-trigger">
          <button className="button is-white" aria-haspopup="true" aria-controls="dropdown-menu4">
            <span>Share</span>
            <span className="icon is-small">
              <FontAwesomeIcon icon={faAngleUp} />
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
          <div className="dropdown-content">
            <ShareLinks slug={post.fields.slug} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default NewsCard