import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'

class ShareLinks extends React.Component {
  constructor(props) {
    super()
    this.value = 'https://www.westlakemud1.com'+props.slug
    this.slug = props.slug

    this.state = {
      copied: false,
    }
  }


  render() {
    return (
      <>
        <a target="_blank" rel="noreferrer noopener" href={'http://www.twitter.com/share?url='+this.value} className="dropdown-item">
          <span className="icon">
            <FontAwesomeIcon icon={faTwitter} />
          </span>
          Twitter
        </a>
        <hr class="dropdown-divider" />
        <a target="_blank" rel="noreferrer noopener" href={'https://www.facebook.com/sharer/sharer.php?u='+this.value} className="dropdown-item">
          <span className="icon">
            <FontAwesomeIcon icon={faFacebookF} />
          </span>
          Facebook
        </a>
        <hr class="dropdown-divider" />
        <CopyToClipboard text={this.value} onCopy={ () => this.setState({copied: true}) }>
          <a className="dropdown-item">
            <span className="icon">
              <FontAwesomeIcon icon={faLink} />
            </span>
            Copy Link { this.state.copied ? <span style={{ color: 'red' }}>Copied.</span> : null }
          </a>
        </CopyToClipboard>
      </>
    )
  }
}

export default ShareLinks;